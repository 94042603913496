.project {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.project:hover{
    box-shadow: 0px 0px 10px 10px #b1822d;
    transition: ease-in-out 0.3s;
}

.git-repo{
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
}

.service-head{
  font-size: 30px;
}

.service-pic{
  width: 100%;
  max-height:400px;
}

.service-description-body{
  color: #FFFFFF;
  padding-top: 10px;
  border-top: 1px solid #b1822d;
  margin-top: 10px;
}

.modal-background{
  background-color: #6c757d;
}

.modal-head-foot{
  background-color: #495057;
}

.img-responsive {
  display: block;
  width: auto;
  max-height: 100%
}

.descr{
    font-size: 22px;
    padding: 20px;
}
.example {
  position: relative;
  padding: 0;
  width: 451px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 5px 5px 5px black;
}
.content {
  opacity: 0;
  font-size: 100px;
  position: absolute;
  top: 0;
  left: 0;
  color: #000000;
  background-color: rgba(200, 200, 200, 0.5);
  width: 451px;
  height: 450px;
  -webkit-transition: all 400ms ease-out;
  -moz-transition: all 400ms ease-out;
  -o-transition: all 400ms ease-out;
  -ms-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  text-align: center;
}
.example .content:hover {
  opacity: 2;
}

.example:hover {
    box-shadow: none;
}

.example .content .text {
  height: 0;
  opacity: 2;
  transition-delay: 0s;
  transition-duration: 0.4s;
}
.example .content:hover .text {
  opacity: 2;
  transform: translateY(170px);
  -webkit-transform: translateY(170px);
}


@media screen and (max-width: 500px){
    .content{
        width: 350px;
    }
    .example{
        width: 350px;
    }
}

@media screen and (min-width: 400px){
  .service-pic{
  min-height: 400px;
}
}