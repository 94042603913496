.custom-font{
    /* font-family: 'Oswald', sans-serif; */
    font-family: "Ovo",'Playfair Display SC', serif;
}

.main{
 background-color: #211D1E;
 color: #FFFFFF;   
}
.crest{
    max-width: 550px;
    padding-bottom: 30px;
}
.underbar{
    width: 100%;
    height: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #211D1E;
}
.image-bg{
    background-color: #211D1E;
}


@media screen and (max-width: 500px) {
    .crest{
        width: 350px;
    }
}

@media screen and (max-width: 375px){
.underbar{
    padding-left: 0px;
    padding-right: 0px;
}

}