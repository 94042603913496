.home-container {
  margin: 30px;
  flex-direction: column;
}

.home-head {
  text-shadow: 0px 0px 4px #b1822d;
  font-size: 50px;
}

.home-main {
  color: white;
  padding-bottom: 30px;
}

.home-info {
  padding: 0px 50px;
  font-size: 16px;
}

.techs {
  padding: 0px 50px;
}

.home-pic {
  max-width: 900px;
  min-width: 430px;
}

.custom-homepic{
  max-width: 500px;
  
}

.customhome-box{
    max-width: 500px;
}
.headshot {

}

.gta-map {
  max-width: 800px;
}

.subhead {
  color: #b1822d;
}

@media screen and (max-width: 950px) {
  .home-container {
    flex-wrap: wrap;
  }
  .home-info {
    padding: 0px 0px;
  }
  .headshot {
    flex-direction: row;
  }
  .home-pic {
    min-width: 0px;
  }
}


@media screen and (max-width: 750px) {
  .headshot {
    flex-direction: column;
  }
  .map-section {
    flex-wrap: wrap;
  }
  .custom-homepic {
    width: 100%;
    max-height: 600px;
  }
}

@media screen and (max-width: 450px) {
  .techs {
    flex-wrap: wrap;
    padding: 0px 5px;
  }
  .custom-homepic {
    width: 100%;
    max-height: 400px;
  }
}
