.footer {
  padding: 10px;
  padding-top: 60px;
}

.footer > * {
  margin-left: 20px;
  margin-right: 20px;
}

.instagram {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  font-size: 220px;
  line-height: 250px;
  vertical-align: middle;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}

.insta-container{
    padding-top: 10px;
}

.facebook {
  color: #1877f2;
  font-size: 45px;
}

.whatsapp {
  color: #25d366;
  font-size: 45px;
}
