.custom-nav > *{
    margin-right: 50px;
    margin-left: 50px;
        color: white;
}

.big-logo{
    font-size: 60px;
    color: #0a58ca
}

.custom-key{
    color:#0a58ca
}

.custom-button{
    color: #FFFFFF;
    border: 1px solid white;
}

#nav-toggler{
    background-color: #b1822d;
}

.custom-underline{
    background-color: #211D1E;
}
.toggle-btn{
    margin-bottom: 20px;
    margin-top: 10px;
}
