:root{
    --darkBlue: rgb(44, 85, 98);
    --marginBlue: rgb(16, 167, 162);
    --borderBlue: rgb(9, 110, 141);
}

main{
    background-color: aliceblue;
    padding-top: 20px;
    padding-bottom: 20px;
}

.grid-container{   /* Setting up grid for each section */
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: minmax(300px, auto);
    grid-gap: 60px;
    grid-template-areas:
    "about"
    "projects";
}

.proj-pic{

    max-height: 600px;
}

.project-container{ /* Container for projects section */
    grid-area: projects;
    padding: 1rem;
    border: 1px solid #b1822d;
    box-shadow: 3px 3px 5px 6px #000000;
    display: flex;
    flex-direction: column;
}


.standard-container{ /* The standard orientation of the container has rounded corners on the left hand of the page*/
    margin-left: 20px;
    border-radius: 50px 0px 0px 50px;
    border-right: 0px;
}

.flip-container{ /* Flipped orientation has rounded corners on the right*/
    margin-right: 20px;
    border-radius: 0px 50px 50px 0px; 
    border-left: 0px;
}

.flipped-about{
    align-self: end;
}

.picture-container{
    display: flex;
    justify-content: space-around;
    align-self: center;
    max-width: 1550px;
}


.modal-picture{
  display: block;
  width: 100%;
  max-height: 100%

}


.project-head{ /* h3 tags are for setting styles and border for all subheadings in each container */
    font-size: 38px;
    padding: 10px;
    border-bottom: 1px solid #b1822d;
}

.header-content{ /* Formats each subheader to look the same */
    flex-direction: row;
    flex: 1;
    margin-left: 30px;
    margin-right: 30px;
}

.flipped-header{ /* flips the subheader text for alternating containers */
    text-align: end;
}

.writing-format{ /* Any paragraphs used in multiple sections is formatted with this code */
    margin-top: 30px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: #FFFFFF;
    padding-right: 10px;
    border-right: 1px solid #b1822d;
    padding-left: 10px;
    border-left: 1px solid #b1822d;
    padding-top: 20px;
    padding-bottom: 20px;
}

    /* About section starts here */
.about-content{
    display: flex;
}

.about-content img{
    margin: 15px ;
    margin-top: 15px;
    display: flex;
    align-self: center;
}

.about-content p{
    align-self: center;
}

    /* Project content starts here */
.projects-content{
    display: flex;
    flex-direction: row;
    margin: 30px;
    margin-right: 70px;
    position: relative;
}
    /* Section below formats the writing in the project section */
.projects-info{
    display: block;
    flex-wrap: wrap;
    flex: 2;
    position: relative;
    top: 100px
}


    /* Contact section starts here */


@media  screen and (max-width: 540px) { /* Centers the entire nav bar and logo */
    h1 {
        justify-content: center;
    }
    nav ul{
        justify-content: center;
    }
    .projects-pics{
        width: 270px;
    }
    .first-child{
        flex: 1;
        flex-basis: 50%;
        width: 270px;
    }
    .other-child{
        flex-basis: 50%;
    }
}
@media screen and (max-width: 1104px) { /* Stops the about section from getting too small  */
    .about-content {
        flex-wrap: wrap;
        /* justify-content: center; */
    }
}
@media screen and (max-width: 695px) { /* Stops the projects content from squishing together */
    .projects-content{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .projects-info{
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-basis: 100%;
        margin-top: -70px;
    }
    .projects-pics{
        flex-direction: row;
        margin-top: 80px;
    }
}