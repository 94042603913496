.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-head{
    text-shadow: 0px 4px 4px #b1822d;
    font-size: 50px;
}

.contact-writing{
    font-size: 28px;
    padding: 5px;
}

.email-link{
  text-decoration: none;
  color: white;
}

.email-link:hover{
  color: #b1822d;
  text-shadow: 0 5px 15px #b1822d;
  transition: ease-in-out 0.2s;
}

.fix-input{
    display: flex;
    justify-content: center;
}

.custom-form{
    width: 500px;
}

.message{
    height:100px;
    resize: none;
}

.custom-form > *{
    margin-top: 20px;
}

.custom-alert{
    margin-top: 10px;
}

.error-text{
    margin-bottom: 0px;
}

@media screen and (max-width: 640px){
    .custom-form{
        width: 300px;
    }
    .contact-writing{
        font-size: 20px;
    }
}

@media screen and (max-width: 400px){
    .custom-form{
        width: 200px;
    }
    .contact-writing{
        font-size: 18px;
    }
}